import React, { useEffect, useState } from "react";
import {useParams } from "react-router-dom";
import {
  StyledOrderBlock,
  StyledBottomBlock,
  StyledBlockWrapper,
} from "./style";
import rightArrow from "../../assets/images/right-arrow.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderDetailsByOrderId,
  getUploadDocDetails,
  resendMail,
  uploadVisaDocument,
  getAllFolderCount
} from "../../redux/orderSlice";
import moment from "moment";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import AddComment from "./AddComment/AddComment";
import MyModal from "../Modal/Modal";
import EmailContent from "./EmailContent/EmailContent";
import { fileExists } from "../../utility/utility";

const ViewOrders = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const [show, setShow] = useState(false);

  const OrderDetails = useSelector((state) => state?.order?.orderDetails);
  // console.log('OrderDetails: ', OrderDetails)
  const UploadDocDetails = useSelector((state) => state?.order?.uploadDocDetails);
  const isLoading = useSelector((state) => state?.order?.loading);
  const [selectedData, setSelectedData] = useState({
    showData: false,
    dataId: 0,
  });

  const [emailId, setEmailId] = useState(
    OrderDetails?.userPersonalInfo
      ?.length > 0
      ? OrderDetails?.userPersonalInfo
      [0]?.primary_email
      : null
  );
  const [orderid, setEmailIdOrderid] = useState(OrderDetails?.order_id);
  const [formData, setFormData] = useState([
    {
      fullName: ""
    }
  ],
  );
  const [formError, setFormError] = useState({});

  const handleInputChange = (index, event, full_name) => {
    const { name, value, files } = event.target;
    setFormData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        [full_name]: name === 'file' ? files[0] : value,
        fullName: full_name ? full_name : "",
      };
      return newData;
    });
  };

  const uploadSchema = yup.object({
    email: yup.string().required("Please enter your email"),
  });

  useEffect(() => {
    if (orderId) {
      dispatch(getOrderDetailsByOrderId(orderId));
      // for displaying last record on the download button for add member in upload doc section
      dispatch(getUploadDocDetails(orderId));
    }

    document.title = "Orders Details | Egypt E-visa";
  }, [dispatch, orderId]);

  useEffect(() => {
    if (
      (OrderDetails &&
        typeof OrderDetails !== "undefined" &&
        OrderDetails?.userPersonalInfo?.length > 0 &&
        typeof emailId === "undefined") ||
      orderId !== orderid
    ) {
      setEmailId(
        OrderDetails?.userPersonalInfo?.length > 0
          ? OrderDetails?.userPersonalInfo[0]?.primary_email
          : null
      );
    }
  }, [OrderDetails, orderid]);

  let defaultInitialValues = {
    orderId: OrderDetails?.order_id,
    email: "",
    fileUpload: "",
  };

  let uploadArr = {};
  if (UploadDocDetails?.length > 0 && UploadDocDetails !== null && UploadDocDetails != "undefined") {
    for (const item of UploadDocDetails) {
      uploadArr[item?.customer_name] = item?.file1;
    }
  }

  if (orderId !== null && orderId !== undefined && OrderDetails !== null) {
    defaultInitialValues = {
      orderId: OrderDetails?.order_id,
      email:
        OrderDetails?.userPersonalInfo?.length > 0
          ? OrderDetails?.userPersonalInfo[0]?.primary_email
          : null,
      fileUpload: "",
    };
  }

  let [initialValues, setInitialValues] = useState(defaultInitialValues);
  // const [fullName, setFullName] = useState("");
  const checkFileExist = (url) => {
    if (typeof url !== "undefined" && url !== null && url !== "") {
      return fileExists(url).then((response) => {
        let returnResponse = response || response == "true" ? true : false;
        return returnResponse;
      });
    }
  };

  const validation = (valuesForm) => {
    if (valuesForm.length == 1 && valuesForm[0]?.fullName == "") {
      setFormError({ file: "One file must be uploaded." })
      return false;
    }
    return true;
  }

  const {
    values,
    errors,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: orderId !== null ? defaultInitialValues : initialValues,
    enableReinitialize: true,
    validationSchema: uploadSchema,
    onSubmit: (values) => {
      if (validation(formData)) {
        const data = new FormData();
        data.append("orderId", values.orderId);
        data.append("email", values.email);
        formData.forEach((user, index) => {
          const fullName = user.fullName;
          const file = user[fullName];
          data.append(`fullName[${index}]`, fullName);
          if (file instanceof File) {
            data.append(`fileUpload`, file);
            data.append(`fileInfo[${fullName}]`, file?.name)
          }
        });
        dispatch(uploadVisaDocument(data))
          .unwrap()
          .then((res) => {
            resetForm();
            if (res.length > 0 && res[2].status === 1) {
              toast.success(`${res[2].message}`, {
                className: "toast-message",
              })
              dispatch(getOrderDetailsByOrderId(orderId));
              dispatch(getUploadDocDetails(orderId));
              dispatch(getAllFolderCount());
            } else {
              toast.error("Document is not uploaded successfully.", {
                className: "toast-message",
              })
            }
          });
      }
    },
  });

  const handleResendMail = (e) => {
    e.preventDefault();
    const data = {
      orderId: OrderDetails?.order_id,
      email: emailId,
    };
    dispatch(resendMail(data))
      .unwrap()
      .then((res) => {
        if (res.status === 1) {
          toast.success(`${res.message}`, {
            className: "toast-message",
          });
          dispatch(getOrderDetailsByOrderId(orderId));
          dispatch(getUploadDocDetails(orderId));
        }
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {OrderDetails?.mailHistory?.length !== 0 && (
            <StyledOrderBlock>
              <h2>Email History</h2>
              <div className="table-block table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "10%" }}>#</th>
                      <th style={{ width: "15%" }}>Order ID</th>
                      <th style={{ width: "35%" }}>Download</th>
                      <th style={{ width: "10%" }}>Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {OrderDetails?.mailHistory?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="font-normal">{item?.order_id}</td>
                          {/* {OrderDetails?.userPersonalInfo?.map((item, index) => { */}
                          {/* return ( */}
                          <tr>
                            <td key={index}>
                              {/* {OrderDetails?.uploadDoc?.length !== 0 && ( */}
                              <a
                                href={`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}visaDocs/${item?.uploadDoc?.file1}`}
                                className="blue-btn"
                                // download
                                target="_blank"
                                rel="noreferrer"
                              >
                                Download File -
                                <span>
                                  {`${item?.uploadDoc?.customer_name}`}
                                </span>
                              </a>
                              {/* <a
                                      href={
                                        OrderDetails?.uploadDoc !== undefined
                                          ? checkFileExist(
                                              `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}visaDocs/${OrderDetails?.uploadDoc[0]?.file1}`
                                            )
                                            ? `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}visaDocs/${OrderDetails?.uploadDoc[0]?.file1}`
                                            : `https://egypt-portal.site/uploads/${OrderDetails?.uploadDoc[0]?.file1}`
                                          : null
                                      }
                                      className="blue-btn"
                                      target="_blank"
                                      // download
                                      rel="noreferrer"
                                    >
                                      Download File -
                                      <span>
                                        {item?.first_name +
                                          " " +
                                          item?.last_name}
                                      </span>
                                    </a> */}
                              {/* )} */}
                            </td>
                          </tr>
                          {/* ); */}
                          {/* //  })} */}

                          <td>
                            {moment(item?.create_ts).format(
                              "MM-DD-YYYY h:mm:ss"
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <form className="form-inline">
                  <div className="form-group">
                    <label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control"
                        value={emailId}
                        onChange={(e) => setEmailId(e.target.value)}
                      />
                    </label>
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="blue-btn"
                      id="download_btn"
                      disabled={isLoading}
                      onClick={handleResendMail}
                    >
                      Resend Mail{" "}
                      <span>
                        <img src={rightArrow} alt="rightArrow" />
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </StyledOrderBlock>
          )}
        </div>
        <div className="col-md-12">
          <StyledOrderBlock>
            <h2>Upload Document</h2>
            <div className="table-block table-responsive fw-mobileview">
              <form onSubmit={handleSubmit}>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ width: "15%" }}>Order ID</td>
                      <td style={{ width: "35%" }} className="font-normal">
                        <input
                          type="text"
                          name="orderId"
                          className="form-control"
                          placeholder="Order ID"
                          disabled
                          value={values?.orderId}
                        />
                      </td>
                      <td style={{ width: "15%" }}>Email ID</td>
                      <td style={{ width: "35%" }}>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          value={values?.email}
                          onChange={handleChange}
                          placeholder="Email"
                          disabled
                        />
                        <p>{errors.email}</p>
                      </td>
                    </tr>
                    {OrderDetails?.userPersonalInfo?.length !== 0 ? (
                      OrderDetails?.userPersonalInfo?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              PDF - {data?.first_name + " " + data?.last_name}
                            </td>
                            <tr>
                              <td>
                                <label className="file-upload">
                                  <input
                                    type="file"
                                    className=""
                                    id="fileUpload"
                                    name="file"
                                    accept=".pdf,image/*"
                                    disabled={values.fileUpload !== ""}
                                    onChange={(e) => {
                                      // setFieldValue(
                                      //   "fileUpload",
                                      //   e.currentTarget.files[0]
                                      // )
                                      handleInputChange(index, e, data?.first_name + " " + data?.last_name)
                                      // setFullName(data?.first_name + " " + data?.last_name)
                                      setFormError({ file: "" })
                                    }
                                    }
                                    onBlur={handleBlur}
                                  />
                                  <span>Choose File</span>
                                </label>
                                <span className="slcted-file"></span>
                                {formError.file ? (<p>{formError.file}</p>) : null}
                                {/* <p>{errors.fileUpload}</p> */}
                              </td>
                            </tr>


                            <td>
                              {(OrderDetails?.uploadDoc?.length !== 0 && uploadArr.length !== 0 && uploadArr[data?.first_name + " " + data?.last_name]) && (
                                <a
                                  href={
                                    OrderDetails?.uploadDoc !== undefined
                                      ? `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}visaDocs/${uploadArr[data?.first_name + " " + data?.last_name] ? uploadArr[data?.first_name + " " + data?.last_name] : ""}`
                                      : null
                                  }
                                  className="blue-btn"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Download File-
                                  <span>
                                    {data?.first_name + " " + data?.last_name}
                                  </span>
                                </a>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td>PDF</td>
                        <tr>
                          <td>
                            <label className="file-upload">
                              <input
                                type="file"
                                className=""
                                id="fileUpload"
                                name="fileUpload"
                                accept=".pdf,image/*"
                                onChange={(e) =>
                                  setFieldValue(
                                    "fileUpload",
                                    e.currentTarget.files[0]
                                  )
                                }
                                onBlur={handleBlur}
                              />
                              <span>Choose File</span>
                            </label>
                            <span className="slcted-file"></span>
                            <p>{errors.fileUpload}</p>
                          </td>
                        </tr>
                      </tr>
                    )}
                  </tbody>
                </table>
                <br />
                <button
                  type="submit"
                  className="blue-btn"
                  id="submit_btn"
                  disabled={isLoading}
                >
                  Submit{" "}
                  <span>
                    <img src={rightArrow} alt="rightArrow" />
                  </span>
                </button>
              </form>
            </div>
          </StyledOrderBlock>
        </div>
        <div className="col-md-12">
          <StyledOrderBlock>
            <h2>Order Details</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.order_id && (
                    <tr>
                      <td>Order ID:</td>
                      <td>{OrderDetails?.order_id}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
          <StyledOrderBlock>
            <h2>User Information</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.application_type && (
                    <tr>
                      <td>Applicant Type</td>
                      <td>{OrderDetails?.application_type}</td>
                    </tr>
                  )}

                  {OrderDetails?.visa_type && (
                    <tr>
                      <td>Visa Type</td>
                      <td>{OrderDetails?.visa_type}</td>
                    </tr>
                  )}

                  {OrderDetails?.entry_type && (
                    <tr>
                      <td>Entry Type</td>
                      <td>{OrderDetails?.entry_type}</td>
                    </tr>
                  )}

                  {OrderDetails?.travelling_from && (
                    <tr>
                      <td>Travelling From</td>
                      <td>{OrderDetails?.travelling_from}</td>
                    </tr>
                  )}

                  {OrderDetails?.expected_arrival_date && (
                    <tr>
                      <td>Expected Arrival Date (MM/DD/YYYY)</td>
                      <td>{OrderDetails?.expected_arrival_date}</td>
                    </tr>
                  )}

                  {OrderDetails?.expected_departure_date && (
                    <tr>
                      <td>Expected Departure Date (MM/DD/YYYY)</td>
                      <td>{OrderDetails?.expected_departure_date}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>

          <StyledBlockWrapper>
            <h2>Applicant Information</h2>
            <div className="col-md-12">
              {OrderDetails?.userPersonalInfo?.length !== 0 &&
                OrderDetails?.userPersonalInfo?.map((item, index) => {
                  return (
                    <>
                      <StyledOrderBlock key={index}>
                        <h2>Personal {index + 1} Information</h2>
                        <div className="table-block table-responsive">
                          <table>
                            <tbody>

                              {item?.is_apply_behalf_someone_else && (
                                <tr>
                                  <td>Are you applying on behalf of someone else ?</td>
                                  <td>{item?.is_apply_behalf_someone_else}</td>
                                </tr>
                              )}
                              {item?.country_of_citizenship && (
                                <tr>
                                  <td>Country of Nationality</td>
                                  <td>{item?.country_of_citizenship}</td>
                                </tr>
                              )}

                              {item?.first_name && (
                                <tr>
                                  <td>First Name or Given Name</td>
                                  <td>{item?.first_name}</td>
                                </tr>
                              )}

                              {item?.father_name && (
                                <tr>
                                  <td>Father Name or Middle Name </td>
                                  <td>{item?.father_name}</td>
                                </tr>
                              )}

                              {item?.last_name && (
                                <tr>
                                  <td>Last name or Family Name</td>
                                  <td>{item?.last_name}</td>
                                </tr>
                              )}

                              {item?.gender && (
                                <tr>
                                  <td>Gender</td>
                                  <td>{item?.gender}</td>
                                </tr>
                              )}

                              {item?.marital_status && (
                                <tr>
                                  <td>Marital Status</td>
                                  <td>{item?.marital_status}</td>
                                </tr>
                              )}

                              {item?.dob && (
                                <tr>
                                  <td>Date of Birth</td>
                                  <td>{item?.dob}</td>
                                </tr>
                              )}

                              {item?.countryOfBirth && (
                                <tr>
                                  <td>Country Of Birth/Citizenship</td>
                                  <td>{item?.countryOfBirth}</td>
                                </tr>
                              )}

                              {item?.cityOfBirth && (
                                <tr>
                                  <td>City Of Birth</td>
                                  <td>{item?.cityOfBirth}</td>
                                </tr>
                              )}

                              {item?.profession && (
                                <tr>
                                  <td>Profession</td>
                                  <td>{item?.profession}</td>
                                </tr>
                              )}

                            </tbody>
                          </table>
                        </div>
                      </StyledOrderBlock>

                      <StyledOrderBlock key={index}>
                        <h2>Current Residence Address {index + 1} </h2>
                        <div className="table-block table-responsive">
                          <table>
                            <tbody>

                              {item?.country && (
                                <tr>
                                  <td>Country</td>
                                  <td>{item?.country}</td>
                                </tr>
                              )}
                              {item?.city_name && (
                                <tr>
                                  <td>City</td>
                                  <td>{item?.city_name}</td>
                                </tr>
                              )}
                              {item?.zip_code && (
                                <tr>
                                  <td>Zip/Postal Code</td>
                                  <td>{item?.zip_code}</td>
                                </tr>
                              )}
                              {item?.addreass && (
                                <tr>
                                  <td>Address</td>
                                  <td>{item?.addreass}</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </StyledOrderBlock>

                      <StyledOrderBlock key={index}>
                        <h2>Passport {index + 1} Information</h2>
                        <div className="table-block table-responsive">
                          <table>
                            <tbody>

                              {item?.paasport_type && (
                                <tr>
                                  <td>Passport Type</td>
                                  <td>{item?.paasport_type}</td>
                                </tr>
                              )}
                              {item?.passport_number && (
                                <tr>
                                  <td>Passport Number</td>
                                  <td>{item?.passport_number}</td>
                                </tr>
                              )}

                              {item?.PassportIssuePlace && (
                                <tr>
                                  <td>Passport Issue Place (Country or City)</td>
                                  <td>{item?.PassportIssuePlace}</td>
                                </tr>
                              )}

                              {item?.passport_issue_date && (
                                <tr>
                                  <td>Passport Issue Date (DD/MM/YY) </td>
                                  <td>{item?.passport_issue_date}</td>
                                </tr>
                              )}

                              {item?.passport_exp_date && (
                                <tr>
                                  <td>Passport Expiry Date (DD/MM/YY)</td>
                                  <td>{item?.passport_exp_date}</td>
                                </tr>
                              )}

                              {item?.expected_date_arrival && (
                                <tr>
                                  <td>Expected Date of Arrival (DD/MM/YY)</td>
                                  <td>{item?.expected_date_arrival}</td>
                                </tr>
                              )}

                              {item?.expected_day_departure && (
                                <tr>
                                  <td>Expected Date of Departure (DD/MM/YY)</td>
                                  <td>{item?.expected_day_departure}</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </StyledOrderBlock>

                      <StyledOrderBlock key={index}>
                        <h2>Additional Purpose of Visit {index + 1} </h2>
                        <div className="table-block table-responsive">
                          <table>
                            <tbody>

                              {item?.eventPurposeofVisit && (
                                <tr>
                                  <td>Purpose of Visit</td>
                                  <td>{item?.eventPurposeofVisit}</td>
                                </tr>
                              )}

                            </tbody>
                          </table>
                        </div>
                      </StyledOrderBlock>

                      <StyledOrderBlock key={index}>
                        <h2>Residence Address in Saudi Arabia {index + 1} </h2>
                        <div className="table-block table-responsive">
                          <table>
                            <tbody>

                              {(item?.residence_or_Commercial && item?.residence_or_Commercial === 'Residential or Relative') ? (
                                <>
                                  {item?.residence_or_Commercial && (
                                    <tr>
                                      <td>{item?.residence_or_Commercial}</td>
                                      <td>{item?.residence_or_Commercial}</td>
                                    </tr>
                                  )}

                                  {item?.name_of_person && (
                                    <tr>
                                      <td>Name of Person</td>
                                      <td>{item?.name_of_person}</td>
                                    </tr>
                                  )}
                                  {item?.CityId && (
                                    <tr>
                                      <td>City</td>
                                      <td>{item?.CityId}</td>
                                    </tr>
                                  )}
                                  {item?.address_1 && (
                                    <tr>
                                      <td>Address 1</td>
                                      <td>{item?.address_1}</td>
                                    </tr>
                                  )}
                                  {item?.address_2 && (
                                    <tr>
                                      <td>Address 2</td>
                                      <td>{item?.address_2}</td>
                                    </tr>
                                  )}

                                </>
                              )
                                : (
                                  <>
                                    {item?.residence_or_Commercial && (
                                      <tr>
                                        <td>Residential or Relative</td>
                                        <td>{item?.residence_or_Commercial}</td>
                                      </tr>
                                    )}
                                    {item?.name_of_hotel && (
                                      <tr>
                                        <td>Name of hotel</td>
                                        <td>{item?.name_of_hotel}</td>
                                      </tr>
                                    )}
                                  </>
                                )}

                              {(item?.pre_primary_contact_Number && item?.primary_contact_Number) && (
                                <tr>
                                  <td>Phone Number</td>
                                  <td>+{item?.pre_primary_contact_Number}-{item?.primary_contact_Number}</td>
                                </tr>
                              )}

                              {item?.primary_email && (
                                <tr>
                                  <td>Email</td>
                                  <td>{item?.primary_email}</td>
                                </tr>
                              )}

                              {item?.insurance_status && (
                                <tr>
                                  <td>I agreed to the Insurance coverage listed above with a fee of <b>(180.00 SAR)</b></td>
                                  <td>{item?.insurance_status}</td>
                                </tr>
                              )}

                            </tbody>
                          </table>
                        </div>
                      </StyledOrderBlock>

                    </>
                  );
                })}
            </div>
          </StyledBlockWrapper>


          <StyledOrderBlock>
            <h2>Download History</h2>
            <div className="table-block table-responsive">
              <table width="100%">
                <thead>
                  <tr>
                    <th width="5%">#</th>
                    <th width="10%">Order ID</th>
                    <th width="22%">Date & Time</th>
                    <th width="23%">Downloaded File</th>
                    <th width="20%">IP Address</th>
                    <th width="20%">Location</th>
                  </tr>
                </thead>
                <tbody>
                  {OrderDetails?.downloadHistory ? (
                    OrderDetails?.downloadHistory?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item?.order_id}</td>
                          <td>
                            {moment(item?.create_ts).format(
                              "MM-DD-YYYY h:mm:ss"
                            )}{" "}
                            EST
                          </td>
                          <td>
                            {OrderDetails?.uploadDoc?.length !== 0 && (
                              <a
                                href={
                                  OrderDetails?.uploadDoc !== undefined
                                    ? `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}visaDocs/${OrderDetails?.uploadDoc[0]?.file1}`
                                    : null
                                }
                                className="blue-btn"
                                target="_blank"
                                // download
                                rel="noreferrer"
                              >
                                Download File
                              </a>
                            )}
                          </td>
                          <td>{item?.ip}</td>
                          <td>{item?.timezone}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="4" align="center">
                        No record found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <form className="form-inline">
                <div className="form-group">
                  <label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control input-class"
                      value={emailId}
                      onChange={(e) => setEmailId(e.target.value)}
                    />
                  </label>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="blue-btn"
                    id="download_btn"
                    onClick={handleResendMail}
                    disabled={isLoading}
                  >
                    Sent Mail{" "}
                    <span>
                      <img src={rightArrow} alt="rightArrow" />
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </StyledOrderBlock>
          <AddComment />
          <StyledOrderBlock>
            <h2>Comment History</h2>
            <div className="table-block table-responsive">
              <table>
                <thead>
                  <tr>
                    <th width="10%">#</th>
                    <th width="20%">Order ID</th>
                    <th width="20%">Date & Time</th>
                    <th width="15%">Team</th>
                    <th width="35%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {typeof OrderDetails !== "undefined" &&
                    OrderDetails.remarkInfo ? (
                    OrderDetails.remarkInfo?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{item?.order_id}</td>
                          <td>
                            {item?.us_date}
                            {"  "}
                            {item?.us_time}
                          </td>
                          <td>{item?.remark_name}</td>
                          <td>
                            {selectedData.showData &&
                              selectedData.dataId === item.id ? (
                              <div
                                rel="40"
                                className="btn blue-btn"
                                onClick={() =>
                                  setSelectedData({
                                    showData: !selectedData.showData,
                                    dataId: item?.id,
                                  })
                                }
                              >
                                {" "}
                                Hide
                              </div>
                            ) : (
                              <div
                                rel="40"
                                onClick={() =>
                                  setSelectedData({
                                    showData: !selectedData.showData,
                                    dataId: item?.id,
                                  })
                                }
                                className="btn blue-btn"
                              >
                                {" "}
                                Show
                              </div>
                            )}
                            <br />
                            {selectedData.showData &&
                              selectedData.dataId === item.id && (
                                <div id="40">
                                  <textarea
                                    name="remark"
                                    className="form-control"
                                    defaultValue={item?.remark}
                                  ></textarea>
                                  <b>
                                    Whatsapp:
                                    {item?.whatsapp ? item?.whatsapp : "N"}
                                  </b>
                                  <b>
                                    Telephone:
                                    {item?.telephone ? item?.telephone : "N"}
                                  </b>
                                  <b>
                                    Refund:
                                    {item?.status_refund
                                      ? item?.status_refund
                                      : "N"}
                                  </b>
                                  <b>
                                    Voided:
                                    {item?.voided ? item?.voided : "N"}
                                  </b>
                                  <b>
                                    Chargeback:
                                    {item?.chargeback ? item?.chargeback : "N"}
                                  </b>
                                  <b>
                                    ReportError:
                                    {item?.report_error
                                      ? item?.report_error
                                      : "N"}
                                  </b>
                                </div>
                              )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="5" align="center">
                        No record found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
        </div>

        <StyledBottomBlock>
          <button
            type="button"
            className="blue-btn"
            data-toggle="modal"
            data-target="#refundModal"
            onClick={() => setShow(true)}
          >
            View Email Content{" "}
          </button>

          <MyModal
            show={show}
            close={setShow}
            size={"lg"}
            modalHeadTitle={"Email Content"}
            showFooter={true}
            primaryButtonName={"Close"}
            secondaryButtonName={"Send Whatsapp Message"}
            OrderDetails={OrderDetails}
          >
            <EmailContent />
          </MyModal>
        </StyledBottomBlock>
      </div>
    </>
  );
};

export default ViewOrders;
