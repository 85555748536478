import { Route, Routes } from 'react-router-dom';
import './App.css';
import AdminLayout from './containers/Layouts/AdminLayout/AdminLayout';
import AdminTrackLayout from './containers/Layouts/AdminTrackLayout/AdminTrackLayout';
import SignupLayout from './containers/Layouts/SignupLayout';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
function App() {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Routes>
        <Route path="/*" element={<AdminTrackLayout />} />
        <Route path="/admin/*" element={<AdminLayout />} />
        <Route path="/" element={<SignupLayout />} />
      </Routes>
    </>
  );
}

export default App;
