import React from "react";
import { Link } from "react-router-dom";
import { EmailContainer, StyleLogo } from "./style";
import { useSelector } from "react-redux";
import { encryptVal } from "../../../utility/utility";
import AdminLogo from "../../../assets/images/saudi-arabia.png";
// import AdminLogo from "../../../assets/img/saudi-arabia.png";

const EmailContent = () => {
  const OrderDetails = useSelector((state) => state?.order?.orderDetails);
  return (
    <EmailContainer>
      <StyleLogo>
        <img
          src={AdminLogo}
          alt="AdminLogo"
          styel={{ padding: " -37px", width: "130px" }}
        />
      </StyleLogo>
      <hr />
      <p>
        <b>{`Dear Customer: ${OrderDetails?.userPersonalInfo ? OrderDetails?.userPersonalInfo[0]?.first_name : null } ${OrderDetails?.userPersonalInfo ? OrderDetails?.userPersonalInfo[0]?.last_name : null} `}</b>
      </p>
      <p>We would like to inform you that your Saudi E-visa application has been successfully approved.</p>
      <p><b>Please download your E-visa document by clicking the link below.</b></p>
     
      <p><b>Username</b>: <Link to={OrderDetails?.userPersonalInfo[0]?.primary_email ? OrderDetails?.userPersonalInfo[0]?.primary_email : ""}>{OrderDetails?.userPersonalInfo[0]?.primary_email ? OrderDetails?.userPersonalInfo[0]?.primary_email : ""} </Link></p>
      <p><b>Order ID</b> : {OrderDetails?.order_id} </p>

      <Link to={`https://admin-sa.online/track-order/${OrderDetails?.order_id ? encryptVal(OrderDetails?.order_id) : ""}`}  target="_black" className="apply-btn">
        Click Here
      </Link>
      <br/>
      <br/>
      <p><b>E-visa validity:</b></p>
      <p>The eVisa will be valid for one year from the date of issuance.</p>
      <p>The eVisa will be valid for multiple entries, unless stated otherwise on the specific eVisa.</p>
      <p>The maximum length of stay allowed under the eVisa is 3 months.</p>
      <p>If you are granted an eVisa, it cannot be extended.</p>
      <p>If the eVisa expires before entry to KSA, you must apply again and another application fee must be paid. </p>

      <p>We remain at your disposal for any further questions or assistance you may need regarding your Saudi Arabia E-visa.</p>

      <p className="flex-class">
        <b>Customer Service Number:</b> +1 (407) 674 0005
      </p>
      <p className="flex-class">
        <b>Order ID:</b> {OrderDetails?.order_id}
      </p>
      <br /> 

      <p>
        <b>Important Notice:</b>
      </p>
      <p>
        {" "}
        If you are not satisfied with the service you have received or wish to file a complaint, please contact us at  <Link to="mailto:inquiries@saudiarabia-portal.com">
          inquiries@saudiarabia-portal.com{" "}
        </Link>{" "}
        , where we will be happy to assist you or alternatively click on one of the links below for more information.
      </p>
      <p>
      You can access the terms and conditions you agreed to by clicking here<Link to="https://saudiarabia-portal.com/terms.php">
          click here{" "}
        </Link>{" "}
      </p>
      <p>Thank you for using our services.</p>
      <p> Regards,</p>
      <p><b>Processing Department</b></p>
      <p>
        <Link to="https://saudiarabia-portal.com/terms.php">Terms</Link> |{" "}
        <Link to="https://saudiarabia-portal.com/privacy.php">Privacy</Link> |{" "}
        <Link to="https://saudiarabia-portal.com/contact.php">Contact</Link> |{" "}
        <Link to="https://saudiarabia-portal.com/refund.php">Refund</Link>
      </p>
    </EmailContainer>
  );
};

export default EmailContent;
