import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import manageTeamService from "../services/manageTeamService";
import {makeDataDecrypted, CryptoJSEncrypted , CryptoJSDecrypted} from "../utility/utility"; 

export const getTeamMemberList = createAsyncThunk(
  "/admin/team-member-list",
  async () => {
    try {
      const response = await manageTeamService.getTeamMemberList();
      const data = await response.data;
      const decryptedData = CryptoJSDecrypted(data?.encryptResponse);
      return decryptedData;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getTeamMemberById = createAsyncThunk(
  "admin/getTeamMemberById",
  async (teamMemberId) => {
    try {
      const response = await manageTeamService.getTeamMemberById(teamMemberId);
      const data = await response.data;
      const decryptedData = CryptoJSDecrypted(data?.encryptResponse);
      return decryptedData;
    } catch (error) {
      return error;
    }
  }
);

export const deleteTeamMember = createAsyncThunk(
  "/admin/delete-team-member",
  async (data) => {
    try {
      await manageTeamService.deleteTeamMember(data);
      return data.teamMemberId;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const addTeamMember = createAsyncThunk(
  "/admin/add-team-member",
  async (addTeamMember) => {
    try {
      const response = await manageTeamService.addTeamMember(addTeamMember);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateTeamMember = createAsyncThunk(
  "/admin/update-team-member",
  async (updateData) => {
    try {
      const response = await manageTeamService.updateTeamMember(updateData);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const manageTeamSlice = createSlice({
  name: "manageTeam",
  initialState: {
    loading: false,
    error: null,
    teamMemberList: [],
    teamMember: {},
    processIdList: [],
    countryList: [],
    count: null,
  },
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getTeamMemberList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTeamMemberList.fulfilled, (state, action) => {
      state.loading = false;
      state.teamMemberList = action?.payload?.data;
    });
    builder.addCase(getTeamMemberList.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    });
    builder.addCase(getTeamMemberById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTeamMemberById.fulfilled, (state, action) => {
      state.loading = false;
      state.teamMember = action?.payload?.data;
    });
    builder.addCase(getTeamMemberById.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    });
    builder.addCase(addTeamMember.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addTeamMember.fulfilled, (state, action) => {
      state.loading = false;
      state.teamMemberList = [...state.teamMemberList, action?.payload?.data];
    });
    builder.addCase(addTeamMember.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateTeamMember.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateTeamMember.fulfilled, (state, action) => {
      state.loading = false;
      state.teamMemberList = [
        ...state.teamMemberList.filter(
          (x) => x.id !== action?.payload?.data?.id
        ),
        action?.payload?.data,
      ];
      state.teamMember = action?.payload?.data;
    });
    builder.addCase(updateTeamMember.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteTeamMember.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteTeamMember.fulfilled, (state, action) => {
      state.loading = false;
      state.teamMemberList = state.teamMemberList.filter(
        (item) => item.id !== action.payload
      );
    });
    builder.addCase(deleteTeamMember.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default manageTeamSlice.reducer;
