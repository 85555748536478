import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../services/authServices";
import {CryptoJSEncrypted, CryptoJSDecrypted } from "../utility/utility";

export const adminUserLogin = createAsyncThunk(
  "user/adminUserLogin",
  async (user) => {
    try {
      const response = await AuthService.adminUserLogin(user);
      const data = response?.data;
      const token = data?.token;
      localStorage.setItem(
        "user",
        JSON.stringify({
          accessToken: token,
        })
      );
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const changePassword = createAsyncThunk(
  "admin/changePassword",
  async (newPassword) => {
    try {
      // Example usage
      const changeData = {
        newPassword: newPassword
      }
      const encryptedDataD = CryptoJSEncrypted(changeData);
      const response = await AuthService.changePassword({ encryptedDataD });
      const decryptedData = CryptoJSDecrypted(response?.data?.encryptResponse);
      const data = decryptedData;
      localStorage.removeItem("user");
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    error: null,
    data: {},
    isAuth: localStorage.getItem("user") ? true : false,
    role: null,
    userInfoLoading: false,
  },
  reducers: {
    logout: () => ({
      loading: false,
      error: null,
      data: null,
      isAuth: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(adminUserLogin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(adminUserLogin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error.message;
    });
    builder.addCase(adminUserLogin.fulfilled, (state, action) => {
      state.loading = false;
      if (typeof action.payload.data !== "undefined") {
        state.isAuth = true;
        state.data = action.payload.data;
      }
    });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
