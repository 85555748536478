import React from "react";
import { Route, Routes } from "react-router-dom";
import PageNotFound from "../../../pages/PageNotFound";
import TrackOrder from '../../../pages/TrackOrder';
import DownloadHistory from '../../../pages/DownloadHistory';

const Main = (props) => {
  return (
    <main>
      <Routes>
        <Route path="/track-order/:orderId" element={<TrackOrder />} />
        <Route path="/get-new-order-details/:orderId" element={<DownloadHistory />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </main>
  );
};

export default Main;
