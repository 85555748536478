import ApiService from "./ApiService";

export default class OrderService {
    static getAllFolderCount = () =>
        ApiService.get("/admin/get-all-folder-count");

    static getOrdersList = ({ order }) =>
        ApiService.get(`/admin/get-orders-list/${order.orderName}?page=${order.page}&limit=${order.perPage}&delay=1`);
    static getOrderDetailsByOrderId = (orderId) =>
        ApiService.get(`/admin/get-orders-details/${orderId}`);

    static searchOrder = (data) => ApiService.post(`/admin/search-order`, data);
    static uploadVisaDocument = (data) =>
        ApiService.upload("/admin/upload-visa", data);

    static getUploadDocDetails = (orderId) =>
        ApiService.get(`/admin/get-upload-doc-details/${orderId}`);
    // ApiService.post("/admin/upload-visa-document", data);
    static MoveOrderStatus = (data) =>
        ApiService.put(`/admin/move-order-status`, data);

    static deleteOrdersData = (deleteData) =>
        ApiService.put(`/admin/move-order-status`, deleteData);
    static resendMail = (data) => ApiService.post("/admin/resend-mail", data);
}