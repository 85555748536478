import React from 'react'
import { Routes, Route } from "react-router-dom";
import ChangePassword from '../../../pages/Admin/ChangePassword/ChangePassword';
import OrderHistory from '../../../pages/Admin/OrderHistory/OrderHistory';
import NewOrders from '../../../pages/Admin/NewOrders/NewOrders';
import PriorityOrders from '../../../pages/Admin/PriorityOrders/PriorityOrders';
import PandingOrders from '../../../pages/Admin/PandingOrders/PendingOrders';
import CompleteOrders from '../../../pages/Admin/CompleteOrders/CompletedOrders';
import ContactCustomer from '../../../pages/Admin/ContactCustomer/ContactCustomer';
import AwaitingGovt from '../../../pages/Admin/AwaitingGovt/AwaitingGovt';
import AwaitingCustomer from '../../../pages/Admin/AwaitingCustomer/AwaitingCustomer';
import CustomerCalled from '../../../pages/Admin/CustomerCalled/CustomerCalled';
import DeletedOrders from '../../../pages/Admin/DeletedOrders/DeletedOrders';
import RefundOrders from '../../../pages/Admin/RefundOrders/RefundOrders';
import Gateway from '../../../pages/Admin/Gateway/Gateway';
import ManageTeam from '../../../pages/Admin/ManageTeam/ManageTeam';
import AdminLogin from '../../../pages/AdminLogin/AdminLogin';
import ViewOrders from '../../../Components/ViewOrders/ViewOrders';

const Main = () => {
  return (
    <main>
      <Routes>
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/all-order" element={<OrderHistory />} />
        <Route path="/" element={<NewOrders />} />
        <Route path="/recent-order" element={<NewOrders />} />
        <Route path="/priority-order" element={<PriorityOrders />} />
        <Route path="/pending-order" element={<PandingOrders />} />
        <Route path="/completed-order" element={<CompleteOrders />} />
        <Route path="/contact-customer" element={<ContactCustomer />} />
        <Route path="/awaiting-govt-order" element={<AwaitingGovt />} />
        <Route path="/awaiting-order" element={<AwaitingCustomer />} />
        <Route path="/customer-called-order" element={<CustomerCalled />} />
        <Route path="/refund-order" element={<RefundOrders />} />
        <Route path="/archive-order" element={<DeletedOrders />} />
        <Route path="/setting" element={<Gateway />} />
        <Route path="/manage-team" element={<ManageTeam />} />
        <Route path="/manage-team/:teamMemberId" element={<ManageTeam />} />
        <Route path="/view-order/:orderId" element={<ViewOrders />} />
      </Routes>
    </main>
  )
}

export default Main