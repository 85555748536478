import React, { useEffect, useState } from "react";
import DeletedIcon from "../../../../assets/images/icon3.svg";
import AwaitingIcon from "../../../../assets/images/icon4.svg";
import OrderHistoryIcon from "../../../../assets/images/icon5.svg";
import ManageTeamIcon from "../../../../assets/images/manage-team.png";
import RefundOrderIcon from "../../../../assets/images/refund-order-new.png";
import GateWayIcon from "../../../../assets/images/icon8.svg";
import ChangePasswordIcon from "../../../../assets/images/icon10.svg";
import LogoutIcon from "../../../../assets/images/icon11.svg";
import { StyledSidebar, StyledAdminMenu } from "./style";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../redux/authSlice";
import { getAllFolderCount, toggleSidebar } from "../../../../redux/orderSlice";
import { FaTimes } from "react-icons/fa";
import jwt from "jwt-decode";

const AdminSideBar = () => {
  const [isActive, setIsActive] = useState(false);
  const token = localStorage.getItem("user");
  const count = useSelector((state) => state?.order?.count);
  const showSideBar = useSelector((state) => state?.order?.showSideBar);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(()=> {
    if(token){
      dispatch(getAllFolderCount());
    }
  },[location?.pathname])

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("user");
    dispatch(logout());
  };

  let userType;
  if (token !== null) {
    const decodedToken = jwt(token);
    userType = decodedToken?.user?.type;
  }

  return (
    <StyledSidebar>
    <div className={showSideBar ? "sidebar open" : "sidebar"}>
      <StyledAdminMenu>
        <button
          type="button"
          onClick={() => {
            dispatch(toggleSidebar());
          }}
          className="close-menu"
        >
          <FaTimes />
        </button>
        <ul>
          <li className={isActive === "awaiting" ? "active" : null}>
            {" "}
            <NavLink
              to="/admin/awaiting-order"
              onClick={() => setIsActive("awaiting")}
            >
              <span className="side-icon">
                <img src={AwaitingIcon} alt="AwaitingIcon" />
              </span>
              Awaiting Response{" "}
              <span className="count">{count?.awaitingOrderCount ? count?.awaitingOrderCount : 0}</span>
            </NavLink>
          </li>

          <li className={isActive === "awaitingGovt" ? "active" : null}>
            {" "}
            <NavLink
              to="/admin/awaiting-govt-order"
              onClick={() => setIsActive("awaitingGovt")}
            >
              <span className="side-icon">
                <img src={AwaitingIcon} alt="AwaitingIcon" />
              </span>
              Awaiting Govt{" "}
              <span className="count">{count?.awaitingGovtCount ? count?.awaitingGovtCount : 0}</span>
            </NavLink>
          </li>

          <li className={isActive === "customerCalled" ? "active" : null}>
            {" "}
            <NavLink
              to="/admin/customer-called-order"
              onClick={() => setIsActive("customerCalled")}
            >
              <span className="side-icon">
                <img src={AwaitingIcon} alt="AwaitingIcon" />
              </span>
              Customer Called {" "}
              <span className="count">{count?.customerCalledCount ? count?.customerCalledCount : 0}</span>
            </NavLink>
          </li>

          <li className={isActive === "allorder" ? "active" : null}>
            {" "}
            <NavLink
              to="/admin/all-order"
              onClick={() => setIsActive("allorder")}
            >
              <span className="side-icon">
                <img src={OrderHistoryIcon} alt="OrderHistoryIcon" />
              </span>
              Order History{" "}
              <span className="count">{count?.allOrdersCount ? count?.allOrdersCount : 0}</span>
            </NavLink>
          </li>
          <li className={isActive === "archive" ? "active" : null}>
            {" "}
            <NavLink
              to="/admin/archive-order"
              onClick={() => setIsActive("archive")}
            >
              <span className="side-icon">
                <img src={DeletedIcon} alt="DeletedIcon" />
              </span>
              Deleted Orders{" "}
              <span className="count">{count?.deleteOrderCount ? count?.deleteOrderCount : 0}</span>
            </NavLink>
          </li>

          <li className={isActive === "refund" ? "active" : null}>
            {" "}
            <NavLink
              to="/admin/refund-order"
              onClick={() => setIsActive("refund")}
            >
              <span className="side-icon">
                <img src={RefundOrderIcon} alt="RefundOrderIcon" />
              </span>
              Refund Orders{" "}
              <span className="count">{count?.refundOrderCount ? count?.refundOrderCount : 0}</span>
            </NavLink>
          </li>

          <li className={isActive === "change-password" ? "active" : null}>
            <NavLink
              to="/admin/change-password"
              onClick={() => setIsActive("change-password")}
            >
              <span className="side-icon">
                <img src={ChangePasswordIcon} alt="ChangePasswordIcon" />
              </span>
              Change Password{" "}
            </NavLink>
          </li>

         {(userType == "Admin" || userType == "Manager") && (
          <li className={isActive === "setting" ? "active" : null}>
            <NavLink
              to="/admin/setting"
              onClick={() => setIsActive("setting")}
            >
              <span className="side-icon">
                <img src={GateWayIcon} alt="GateWayIcon" />
              </span>
              Gateway
            </NavLink>
          </li>
          )}

          {(userType == "Admin" || userType == "Manager") && (
              <li className={isActive === "manage-team" ? "active" : null}>
                {" "}
                <NavLink
                  to="/admin/manage-team"
                  onClick={() => setIsActive("manage-team")}
                >
                  <span className="side-icon">
                    <img src={ManageTeamIcon} alt="ManageTeamIcon" />
                  </span>
                  Manage Team
                </NavLink>
              </li>
            )}

          <li>
            <NavLink to="/logout" onClick={handleLogout}>
              <span className="side-icon">
                <img src={LogoutIcon} alt="LogoutIcon" />
              </span>
              Logout
            </NavLink>
          </li>
        </ul>
      </StyledAdminMenu>
    </div>
  </StyledSidebar>
  )
}

export default AdminSideBar