import { configureStore } from '@reduxjs/toolkit';
import orderReducer from "./orderSlice";
import authReducer from "./authSlice";
import gateWayReducer from "./gateWaySlice";
import teamMemberReducer from "./manageTeamSlice";
import frontReducer from "./frontSlice";

export default configureStore({
  reducer: {
    order: orderReducer,
    auth: authReducer,
    gateway: gateWayReducer,
    manageTeam: teamMemberReducer,
    front: frontReducer,
  },
});