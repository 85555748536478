import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { SearchField, ButtonWrapper, StyledDataTable } from "./style";
import { Link } from "react-router-dom";
import Modal from "../Modal/Modal";
import rightArrow from "../../assets/images/right-arrow.png";
import moment from "moment";
import { toast } from "react-toastify";
import jwt from "jwt-decode";
import {DebounceInput} from 'react-debounce-input';
import {
  getAllFolderCount,
  deleteOrdersData,
  sendRefundMail,
  handleChange,
  getOrdersList,
  searchOrder
} from "../../redux/orderSlice";
import Pagination from "../customPagination";
import { encryptVal } from "../../utility/utility";
const ReactDataTable = ({
  data,
  hideButtonWrapper,
  buttonWrapper,
  customColumns,
  customData,
  customOrdersData,
  hideSelectableRows,
  orderName,
  setPage,
  setLimit,
  selectableRowDisabled,
  hideSearchInput,
}) => {
  const isLoading = useSelector((state) => state?.order?.loading);
  const [searchText, setSearchText] = useState("");
  const token = localStorage.getItem("user");
  let userType;
  if (token !== null) {
    const decodedToken = jwt(token);
    userType = decodedToken?.user?.type;
  }
  hideButtonWrapper =
    typeof hideButtonWrapper !== "undefined" ? hideButtonWrapper : false;
  buttonWrapper =
    typeof buttonWrapper === "undefined" ? (
      <ButtonWrapper>
        {(userType == "Admin" || userType == "Manager") && (
          <div className="row">
            <div className="col-sm-6">
              <button
                type="button"
                name="submit"
                value="Delete"
                className="btn blue-btn"
                onClick={() => setShow(true)}
              >
                Delete Order{" "}
                <span>
                  <img src={rightArrow} alt="rightArrow" />
                </span>
              </button>
            </div>
          </div>
        )}
      </ButtonWrapper>
    ) : typeof buttonWrapper === "string" && buttonWrapper === "refund" ? (
      <ButtonWrapper>
        {userType == "Admin" || userType == "Manager" ? (
          <div className="row">
            <div className="col-sm-6">
              <button
                type="button"
                name="submit"
                value="Delete"
                className="btn blue-btn"
                onClick={() => setShow(true)}
              >
                Delete Order{" "}
                <span>
                  <img src={rightArrow} alt="rightArrow" />
                </span>
              </button>

              <button
                type="button"
                name="submit"
                value="invoice"
                className="btn blue-btn"
                onClick={() => handleRefundMail()}
                disabled={isLoading}
              >
                Refunded it{" "}
                <span>
                  <img src={rightArrow} alt="rightArrow" />
                </span>
              </button>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-sm-6">
              <button
                type="button"
                name="submit"
                value="invoice"
                className="btn blue-btn"
                onClick={() => handleRefundMail()}
                disabled={isLoading}
              >
                Refunded it{" "}
                <span>
                  <img src={rightArrow} alt="rightArrow" />
                </span>
              </button>
            </div>
          </div>
        )}
      </ButtonWrapper>
    ) : (
      buttonWrapper
    );

  let filteredArray = [];
  let columns;
  let Data = [];
  const dispatch = useDispatch();
  const [selectedData, setSelectedData] = useState();
  const [show, setShow] = useState(false);
  const SelectedData = useSelector((state) => state?.order?.selectedData);
  const count = useSelector((state) => state?.order?.count);
  const [pageNum, setPageNum] = useState(1);
  const [rowLimit, setRowLimit] = useState(10);
  const [filterText, setFilterText] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (orderId) => {
    setShow(true);
    setSelectedData([orderId]);
  };
  let [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (SelectedData && SelectedData?.length !== 0) {
      setSelectedData(SelectedData);
    }
  }, [SelectedData]);

  useEffect(() => {
    setCurrentPage(pageNum);
    orderName?.includes("awaitingorder")
      ? setTotalRows(count?.awaitingOrderCount)
      : orderName?.includes("awaitinggovt")
        ? setTotalRows(count?.awaitingGovtCount)
        : orderName?.includes("priorityorder")
          ? setTotalRows(count?.priorityOrdersCount)
          : orderName?.includes("completed")
            ? setTotalRows(count?.completedOrderCount)
            : orderName?.includes("delete")
              ? setTotalRows(count?.deleteOrderCount)
              : orderName?.includes("contactcustomer")
                ? setTotalRows(count?.contactCustomerOrdersCount)
                : orderName?.includes("customercalled")
                  ? setTotalRows(count?.customerCalledCount)
                  : orderName?.includes("new")
                    ? setTotalRows(count?.newOrderCount)
                    : orderName?.includes("all")
                      ? setTotalRows(count?.allOrdersCount)
                      : orderName?.includes("pending")
                        ? setTotalRows(count?.pendingOrderCount)
                        : orderName?.includes("refund")
                          ? setTotalRows(count?.refundOrderCount)
                          : orderName?.includes("savecontinue")
                            ? setTotalRows(count?.saveContinueOrdersCount)
                            : setTotalRows(0);
  }, [pageNum, count, orderName]);

  const handlePageChange = (page) => {
    setPage(page);
    setPageNum(page);
  };

  const handleRowsChange = (limit) => {
    if (limit === "All") {
      setLimit(totalRows);
      setRowLimit(totalRows);
    } else {
      setLimit(limit);
      setRowLimit(limit);
    }
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    let searchData = {
      start: pageNum,
      end: rowLimit,
      searchVal: e.target.value,
      folderName: orderName,
    };
    if (e.target.value !== "") {
      dispatch(searchOrder(searchData));
    } else {
      dispatch(
        getOrdersList({
          orderName: orderName,
          page: pageNum,
          perPage: rowLimit,
        })
      );
    }
  };

  const handleRefundMail = () => {
    if (
      selectedData !== null &&
      selectedData?.length !== 0 &&
      typeof selectedData !== "undefined"
    ) {
      dispatch(sendRefundMail(selectedData))
        .unwrap()
        .then((res) => {
          if (res.status === 1) {
            toast.success(`${res.message}`, {
              className: "toast-message",
            });
            dispatch(
              getOrdersList({
                orderName: "refundorder",
                page: pageNum,
                perPage: rowLimit,
              })
            );
          } else {
            if (res.status === 0) {
              toast.error(`${res.message}`, {
                className: "toast-message",
              });
            }
          }
        });
    }
  };
  const deleteOrder = () => {
    let data = {
      orderId: selectedData,
      // orderId: selectedData[0]?.id,
      permanentDeletedFlag: orderName === "deletedorder" ? true : false,
      updatingStatus: "Deleted",
    };
    dispatch(deleteOrdersData(data))
      .then((res) => {
        handleClose();
        if (res.payload) {
          toast.success(`Order has been deleted successfully`, {
            className: "toast-message",
          });
          dispatch(
            getOrdersList({
              orderName: orderName,
              page: pageNum,
              perPage: rowLimit,
            })
          );
          dispatch(getAllFolderCount());
        } else {
          toast.error(`${res.message}`, {
            className: "toast-message",
          });
        }
      })
      .catch((err) => {
        if (err.status === 0) {
          toast.success(`${err.message}`, {
            className: "toast-message",
          });
        }
      });
  };

  if (
    data &&
    !customData &&
    typeof data !== "undefined" &&
    typeof data !== null &&
    data?.length !== 0
  ) {
    Data = data?.map((item, index) => {
      return {
        id: item?.order_id,
        status: item?.process_status,
        name: `${item?.userPersonalInfo?.first_name} ${item?.userPersonalInfo?.last_name}`,
        email: item?.userPersonalInfo?.primary_email,
        telephone: item?.userPersonalInfo?.primary_contact_Number ? item?.userPersonalInfo?.primary_contact_Number : '-',
        date: item?.userPersonalInfo?.created_on,
        // refundDate: item?.userPersonalInfo?.refund_date,
      };
    });
  } else if (typeof customData !== "undefined" && customData?.length !== 0) {
    Data = customData?.map((item) => {
      return {
        id: item?.id,
        name: item?.name,
        email: item?.email,
        password: item?.password,
        type: item?.type ? item?.type : "-",
      };
    });
  } else {
    Data = customOrdersData?.map((item) => {
      return {
        id: item?.order_id,
        name: item?.full_name,
        email: item?.email,
        telephone: item?.phone_number,
        date: item?.created_at,
        status: item?.process_status,
        refundDate: item?.refund_date,
      };
    });
  }

  if (filterText !== "") {
    filteredArray = Data?.filter(
      (item) =>
        (item?.id).toLowerCase().includes(filterText.toLocaleLowerCase()) ||
        (item?.applicantInfo?.first_name).toLowerCase().includes(filterText.toLocaleLowerCase()) ||
        (item?.item?.applicantInfo?.applicant_email)
          .toLowerCase()
          .includes(filterText.toLocaleLowerCase()) ||
        (item?.item?.applicantInfo?.applicant_phone).toLowerCase().includes(filterText.toLocaleLowerCase())
    ).map((item) => {
      return {
        id: item?.order_id,
        status: item?.process_status,
        name: item?.applicantInfo?.first_name,
        email: item?.applicantInfo?.applicant_email,
        telephone: item?.applicantInfo?.applicant_phone,
        date: item?.applicantInfo?.create_ts,
        refundDate: item?.applicantInfo?.refund_date,
      };
    });
    Data = filteredArray;
  }

  if (!customColumns) {
    columns = [
      {
        name: "Order Id",
        width: "10rem",
        selector: (row) => row.id,
        sortable: true,
      },
      {
        name: "Name",
        width: "15rem",
        selector: (row) => row.name,
        sortable: true,
      },
      {
        name: "Email",
        width: "15rem",
        selector: (row) => row.email,
        sortable: true,
      },
      {
        name: "Telephone",
        width: "10rem",
        selector: (row) => row.telephone,
        sortable: true,
      },
      {
        name: "Date & Time",
        width: "10rem",
        selector: (row) => row?.date
        ? moment(new Date(row?.date)).format("DD-MM-YYYY hh:mm:ss")
        : "",
        sortable: true,
      },

      {
        name: "Status",
        width: "15rem",
        selector: (row) => row.status,
        sortable: true,
      },
      {
        name: "Action",
        width: "15rem",
        selector: (row) =>
          Data?.length ? (
            <span>
              <Link
                to={`/admin/view-order/${encryptVal(row?.id)}`}
                style={{
                  border: "1px solid #18f",
                  borderRadius: "5px",
                  padding: " 0 5px",
                  minWidth: "80px",
                  display: "inline-block",
                  textAlign: "center",
                  height: "40px",
                  lineHeight: "40px",
                  color: "#111",
                  margin: "5px",
                  textDecoration: "none",
                }}
                className="blue-border"
              >
                View
              </Link>{" "}
              {userType === "Admin" || userType === "Manager" ? (
                <Link
                  to="#"
                  onClick={() => handleShow({ id: row?.id, status: row?.status })}
                  style={{
                    textDecoration: "none",
                    border: "1px solid #e92a11",
                    borderRadius: "5px",
                    padding: " 0 5px",
                    minWidth: "80px",
                    display: "inline-block",
                    textAlign: "center",
                    height: "40px",
                    lineHeight: "40px",
                    color: " #e92a11",
                    margin: "5px",
                  }}
                >
                  Delete
                </Link>
              ) : null}
            </span>
          ) : (
            " "
          ),
      },
    ];

    if (typeof orderName !== "undefined" && orderName !== null) {
      if (orderName === "contactcustomerorder") {
        let FilterColumn = columns.filter((item) => item.name !== "Telephone");
        columns = FilterColumn;
      }
    }
  } else {
    columns = customColumns;
  }

  const CustomMaterialPagination = () => {
    totalRows = typeof totalRows === "undefined" ? 0 : totalRows;
    return (
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={totalRows}
        pageSize={rowLimit}
        onPageChange={(page) => handlePageChange(page)}
        paginationRowsPerPageOptions={["10", "25", "50", "100", "500", "All"]}
        handleRowsChange={handleRowsChange}
      />
    );
  };

  return (
    <>
      <Modal
        show={show}
        close={handleClose}
        size={"s"}
        confirmAlert={true}
        noEvent={() => handleClose()}
        yesEvent={() => deleteOrder()}
      ></Modal>
      {hideSearchInput ? null : (
        <SearchField>
          <DebounceInput
            type="search"
            id="search_field"
            className="search-bar form-control"
            value={searchText}
            onChange={(e) => handleSearch(e)}
            // onChange={(e) => setFilterText(e.target.value)}
            name="search_field"
            placeholder="Search"
            debounceTimeout={500}
          />
        </SearchField>
      )}
      <StyledDataTable>
        <DataTable
          className="uspassport-custom-tbl"
          selectableRows={
            hideSelectableRows ? false : Data?.length ? true : false
          }
          columns={columns}
          data={Data?.length ? Data : [{}]}
          progressPending={isLoading}
          fixedHeader={true}
          fixedHeaderScrollHeight="550px"
          pagination={Data?.length && !customOrdersData ? true : false}
          paginationServer
          paginationTotalRows={totalRows}
          paginationDefaultPage={currentPage}
          paginationComponentOptions={{ selectAllRowsItem: true }}
          noDataComponent="No records found"
          onSelectedRowsChange={(e) => dispatch(handleChange(e))}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsChange}
          selectableRowDisabled={selectableRowDisabled}
          paginationComponent={CustomMaterialPagination}
        />
      </StyledDataTable>
      {!Data?.length && isLoading === false && (
        <p style={{ textAlign: "center", color: "red" }}>
          {"No data available in table"}
        </p>
      )}
      {!hideButtonWrapper && buttonWrapper}
    </>
  );
};

export default ReactDataTable;
