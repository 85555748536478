import CryptoJS from 'crypto-js';


export const CryptoJSEncrypted = (objData) => {
  try {
      if (objData) {
          const secretKey = "F4ENWiJHMFcYlb06QGW_6cr1lh7ZrdjKFCWqjXWQG06t7WBrfGbFZ";
          const encryptedDataD = CryptoJS.AES.encrypt(JSON.stringify(objData), secretKey).toString();
          return encryptedDataD;
      }
      return false;
  } catch (error) {
      // console.log('error: ', error)
      return error;
  }
}
export const CryptoJSDecrypted = (encryptedData) => {
  try {
      if (encryptedData) {
          const secretKey = "F4ENWiJHMFcYlb06QGW_6cr1lh7ZrdjKFCWqjXWQG06t7WBrfGbFZ";
          const result = CryptoJS.AES.decrypt(encryptedData, secretKey).toString(CryptoJS.enc.Utf8);
          const decryptedData = JSON.parse(result);
          return decryptedData;
      }
      return false;
  } catch (error) {
      // console.log('error: ', error)
      return error;
  }

}

export const makeDataEncrypted = (data) => {
  try {
    if (data && typeof data != "undefined") {
      const jsonString = JSON.stringify(data);
      // Encryption key (replace with your own key)
      const encryptionKey = "F4ENWiJHMFcYlb06QGW_6cr1lh7ZrdjKFCWqjXWQG06t7WBrfGbFZ";
      // Encrypt JSON string
      const encrypted = CryptoJS.AES.encrypt(jsonString, encryptionKey).toString();
      return encrypted;
    } else {
      return "Data is empty.";
    }
  } catch (error) {
    return {
      error: error,
    }
  }
};

export const encryptData = (data, key, iv) => {
  try {
    
    const cipherText = CryptoJS.AES.encrypt(data, key, { iv: CryptoJS.enc.Hex.parse(iv) }).toString();
    return cipherText;
  } catch (error) {
    console.log('error: ', error)    
  }
};



export const makeDataDecrypted = (data) => {
  try {
    if (data && typeof data != "undefined") {
      const encrypted = data;
      const encryptionKey = "F4ENWiJHMFcYlb06QGW_6cr1lh7ZrdjKFCWqjXWQG06t7WBrfGbFZ";
      const decrypted = CryptoJS.AES.decrypt(encrypted, encryptionKey).toString(CryptoJS.enc.Utf8);
      // Parse the decrypted JSON string
      const decryptedData = JSON.parse(decrypted);
      return decryptedData;
    } else {
      return "Data is empty.";
    }
  } catch (error) {
    return {
      error: error,
    }
  }
};

export const decryptVal = (string) => {
  try {
    string = atob(string);
    if (!isNaN(string)) {
      return string / 2;
    }
    return string;
  } catch (err) {
    return 0;
  }
};


export const encryptVal = (string) => {
  try {
    if (!isNaN(string)) {
      string = parseInt(string) + parseInt(string);
    }
    string = btoa(string);
    return string.replace(/=+$/, "");
  } catch (err) {
    return 0;
  }
};

export function titleCase(str) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
}

export const capitalizeFirstLowercaseRest = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export async function fileExists(url) {
  let status = false;
  return await fetch(url, { method: "HEAD" })
    .then((response) => {
      status = typeof response.ok !== "undefined" ? true : false;
      return status;
    })
    .catch((error) => {
      return false;
    });
}
