import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../../pages/AdminLogin/AdminLogin";

const SignupLayout = (props) => (
  <Routes>
    <Route path="/" element={<Login />} />
  </Routes>
);

export default SignupLayout;
