import React from 'react'
import AdminHeader from './AdminHeader/AdminHeader'
import AdminSideBar from './AdminSideBar/AdminSideBar'
import AdminNavbar from './AdminNavbar/AdminNavbar'
import { StyledDashboard, StyledMain } from "./style";
import Main from "./Main";

const AdminLayout = (props) => {
  return (
    <>
   <StyledDashboard>
    <AdminNavbar {...props} />
    <AdminSideBar {...props} />
    <StyledMain>
      <AdminHeader />
      <Main {...props} />
    </StyledMain>
  </StyledDashboard>
    </>
  )
}

export default AdminLayout